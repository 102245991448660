// TODO: move to external module
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

var createArrow = function(direction) {
  let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  let arrow = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  arrow.classList.add('arrow')
  arrow.setAttribute('viewBox', '0 0 24 24')
  arrow.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  arrow.appendChild(path);
  if (direction == 'left') {
    path.setAttribute('d', 'M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z');
    arrow.classList.add('arrow--left')
  } else {
    path.setAttribute('d', 'M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z');
    arrow.classList.add('arrow--right')
  }
  return arrow;
}

let updateClasses = function(instance, slidesPerView, arrowLeft, arrowRight) {
  let slide = instance.details().relativeSlide
  let total = instance.details().size
  let last = total - slidesPerView;
  // console.log('update', slide, total, last, slidesPerView)
  slide === 0 ?
    arrowLeft.classList.add("arrow--disabled") :
    arrowLeft.classList.remove("arrow--disabled")
  slide === last ?
    arrowRight.classList.add("arrow--disabled") :
    arrowRight.classList.remove("arrow--disabled")
  if (total < slidesPerView) {
    arrowLeft.classList.add("arrow--disabled")
    arrowRight.classList.add("arrow--disabled")
  }
}

var setBreakpoints = function(type) {
  var breakpoints = {}
  if ( type === 'hero') {
    breakpoints = {
      "(min-width: 320px)": {
        slidesPerView: 1,
        mode: "snap"
      },
    }
  } else if ( type === 'reseller') {
    breakpoints = {
      "(min-width: 320px)": {
        slidesPerView: 2,
        mode: "snap"
      },
    }
  } else {
    breakpoints = {
      "(min-width: 320px)": {
        slidesPerView: 1.25,
        mode: "free-snap",
      },
      "(min-width: 550px)": {
        slidesPerView: 2,
        mode: "free-snap",
      },
      "(min-width: 960px)": {
        slidesPerView: 3,
        mode: "free-snap",
      },
      "(min-width: 1180px)": {
        slidesPerView: 4,
        mode: "free-snap",
      }      
    }
  }
  return breakpoints;
}

var initSliders = function() {
  var sliders = document.querySelectorAll('.slider-wrapper');
  for (var i = 0; i < sliders.length; i++) {    
    let slideshow = sliders[i].querySelector('.slideshow')
    window.initSlider(slideshow);
  }
}

window.initSlider = function(selector) {
  var ks = null
  if (typeof selector == 'string') {
    var slider = document.querySelector(selector);
  } else if ( selector instanceof Element) {
    var slider = selector
  }
  if (slider) {
    // console.log('found: ', slider.id);
    let slides = slider.querySelectorAll('.slide')
    let spv = slider.dataset.slidesPerView
    if (typeof spv == 'undefined') {
      spv = 1;
    }
    let type = slider.dataset.type
    if (typeof type == 'undefined') {
      type = 'default';
    }
    let wrapper = slider.parentElement
    let arrows = wrapper.querySelectorAll('.arrow')
    if (slides.length >= spv) {
      var arrowLeft, arrowRight;
      if (arrows.length < 1) {
        arrowLeft = createArrow('left');
        arrowRight = createArrow('right');
        wrapper.appendChild(arrowLeft);
        wrapper.appendChild(arrowRight);
      } else {
        arrowLeft = arrows[0]
        arrowRight = arrows[1]
      }
      // console.log('init slider:', slider.id, slides.length, type)
      ks = new KeenSlider('#' + slider.id, {
        spacing: 0,
        mode: 'snap',
        slidesPerView: 1,
        breakpoints: setBreakpoints(type), 
        created: function(instance) {
          slider.classList.add('keen-slider');
          arrowLeft.addEventListener('click', function() {
            instance.prev()
          })
          arrowRight.addEventListener("click", function() {
            instance.next()
          })
          updateClasses(instance, spv, arrowLeft, arrowRight)
        },
        slideChanged(instance) {
          updateClasses(instance, spv, arrowLeft, arrowRight)
        },
      });
      
    } else {
      // console.log('slider not needed', slides.length, spv)
      if (arrows.length) {
        arrows[0].remove();
        arrows[1].remove();
      }
    }
  }
  return ks;
}

document.addEventListener('turbolinks:load', initSliders)

